<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form @submit="handleSubmit" :form="form">
      <a-form-item label="昵称" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'nickname',
          {
            rules: [{ required: true, message: '昵称不能为空' }],
            initialValue: adminInfo.nickname
          },
        ]" name="nickname" placeholder="昵称" />
      </a-form-item>
      <a-form-item label="用户名" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'username',
          {
            rules: [{ required: true, message: '请输入用户名' }],
            initialValue: adminInfo.username
          }
        ]" placeholder="登录用户名" />
      </a-form-item>
      <a-form-item label="邮箱" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'email',
          {
            rules: [
              { type: 'email', required: true, message: '请输入邮箱地址' },
            ],
            initialValue: adminInfo.email
          }
        ]" type="email" placeholder="邮箱" />
      </a-form-item>
      <a-form-item label="密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'password',
          {
            rules: [
              {
                required: checkPass, message: '请输入密码'
              },
              {
                validator: (rules, value, callback) => { this.handleCheckPwd(rules, value, callback) }
              }
            ]
          }
        ]" type="password" placeholder="密码" />
      </a-form-item>
      <a-form-item label="确认密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'pwd_confirm',
          {
            rules: [
              {
                required: checkPass, message: '请再次输入密码'
              },
              {
                validator: (rules, value, callback) => { this.handleCfmPwd(rules, value, callback) }
              }
            ]
          }
        ]" type="password" placeholder="注：修改用户时，如果填了密码和确认密码，系统则默认是重置密码行为" />
      </a-form-item>
      <a-form-item label="电话号码" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'phone',
          {
            rules: [{ required: true, message: '请输入电话号码' }],
            initialValue: adminInfo.phone
          },
        ]" style="width: 100%">
          <a-select slot="addonBefore" v-decorator="['pre_code', { initialValue: adminInfo.pre_code }]"
            style="width: 70px">
            <a-select-option value="86">
              +86
            </a-select-option>
            <a-select-option value="87">
              +852
            </a-select-option>
          </a-select>
        </a-input>
      </a-form-item>
      <a-form-item label="头像链接" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-input v-decorator="[
          'avatar',
          {
            initialValue: adminInfo.avatar
          }
        ]" placeholder="avatar头像链接" />
      </a-form-item>
      <a-form-item label="权限组" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-select v-decorator="[
          'gid',
          {
            rules: [{ required: true, message: '请选择管理组' }],
            initialValue: adminInfo.gid
          }
        ]" placeholder="选择管理员组">
          <a-select-option v-for="option in adminGroupList" :key="option.id" :value="option.id">{{ option.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="语言" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-select v-decorator="[
          'lang',
          {
            rules: [{ required: true, message: '请选择默认语言' }],
            initialValue: adminInfo.lang
          }
        ]" placeholder="选择默认语言">
          <a-select-option key="zh-CN">
            zh-CN
          </a-select-option>
          <a-select-option key="en-US">
            en-US
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-checkbox v-decorator="[
          'status',
          {
            valuePropName: 'checked',
            initialValue: adminStatus,
          },
        ]">
          是否启用
        </a-checkbox>
      </a-form-item>
      <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
        <a-textarea rows="4" placeholder="请输入管理员描述信息" v-decorator="[
          'desc',
          {
            initialValue: adminInfo.desc
          }
        ]" />
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
        <a-button style="margin-left: 8px" @click="routerback">返回</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
import { getAdminInfo, adminGroupList, adminUpdate, adminAdd } from '@/api/admin'

export default {
  name: 'EditAdmin',
  data() {
    return {
      description: '管理员创建编辑页面',
      value: 1,
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      queryData: {},
      checkPass: true,
      adminInfo: {},
      adminGroupList: {},
      pageType: 'add', // 当前页面是新增(add),还是修改(edit)

      // form
      form: this.$form.createForm(this),
      adminStatus: false,
      submitLoading: false
    }
  },
  created() {
    this.queryData = this.$route.query
    if (this.queryData.id !== undefined) {
      this.pageType = 'edit'
      this.checkPass = false
      // 获取数据
      const param = {}
      param.id = this.queryData.id
      new Promise((resolve, reject) => {
        getAdminInfo(param).then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.adminInfo = res.data
        if (res.data.status === 1) {
          this.adminStatus = true
        }
      }).catch(error => {
        this.$notification.error({
          message: '错误',
          description: error.message
        })
        // 跳转回管理员管理页面
        setTimeout(() => {
          this.$router.push('/config/admin/list')
        }, 2000)
      })
    }
    // 获取管理员组
    new Promise((resolve, reject) => {
      adminGroupList().then(response => {
        if (response.status === 'success') {
          resolve(response)
        } else {
          reject(response)
        }
      })
    }).then(res => {
      console.log(res.data)
      this.adminGroupList = res.data
    }).catch(error => {
      this.$notification.error({
        message: '错误',
        description: error.message
      })
    })
  },
  methods: {
    handleCheckPwd(rules, value, callback) {
      var cfmPwd = this.form.getFieldValue('pwd_confirm')
      if (cfmPwd && cfmPwd !== value) {
        callback(new Error('两次密码输入不一致'))
      } else {
        // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
        callback()
      }
    },
    handleCfmPwd(rules, value, callback) {
      var password = this.form.getFieldValue('password')
      if (password && password !== value) {
        callback(new Error('两次密码输入不一致'))
      } else {
        // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
        callback()
      }
    },
    // handler
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          values.status = values.status ? 1 : -1
          if (this.pageType === 'edit') {
            new Promise((resolve, reject) => {
              this.submitLoading = true
              values.id = this.queryData.id
              adminUpdate(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 跳转回管理员管理页面
              setTimeout(() => {
                this.$router.back(-1)
              }, 2000)
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            // 新增
            new Promise((resolve, reject) => {
              this.submitLoading = true
              adminAdd(values).then(response => {
                if (response.status === 'success') {
                  resolve(response)
                } else {
                  reject(response)
                }
              })
            }).then(res => {
              this.$notification.success({
                message: res.status,
                description: res.message
              })
              // 跳转回管理员管理页面
              setTimeout(() => {
                this.$router.back(-1)
              }, 2000)
            }).catch(error => {
              this.$notification.error({
                message: '错误',
                description: error.message
              })
            }).finally(() => {
              this.submitLoading = false
            })
          }
        }
      })
    },
    routerback: function () {
      this.$router.back(-1)
    }
  }
}
</script>
